import React from 'react';
import colours from '../../utils/colours';

import { MainColour, MainDark, TitleColour } from '../../utils/variables';

function withHover(Component) {
  class ButtonHover extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        hover: false,
      };
      this.toggleHover = this.toggleHover.bind(this);
      this.hoverColour = this.hoverColour.bind(this);
    }

    hoverColour = alt => {
      const { primary, dark } = this.props;
      const { hover } = this.state;

      if (primary) {
        if (alt) {
          return !hover ? TitleColour : colours.lightenDarken(TitleColour, -10);
        }
        return !hover ? MainColour : MainDark;
      }

      if (dark) {
        return !hover ? `transparent` : TitleColour;
      }

      return !hover ? `transparent` : `#fff`;
    };

    toggleHover() {
      const { hover } = this.state;
      this.setState({ hover: !hover });
    }

    render() {
      const { hover } = this.state;
      return (
        <Component
          {...this.props}
          hover={hover}
          toggleHover={this.toggleHover}
          hoverColour={this.hoverColour}
        />
      );
    }
  }
  return ButtonHover;
}

export default withHover;
