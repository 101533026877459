import { BorderRadius } from '../../utils/variables';

import Loader from '../../images/icon-loader.svg';

export const buttonStyles = {
  display: `inline-flex`,
  padding: `16px 45px`,
  borderRadius: BorderRadius,
  borderColor: `transparent`,
  cursor: `pointer`,
  textAlign: `center`,
  backgroundSize: '18px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '90% 50%',
  width: `100%`,
  alignItems: `center`,
  justifyContent: `center`,
};

export const buttonInactive = {
  opacity: 0.6,
  pointerEvents: 'none',
};

export const buttonLoading = {
  ...buttonInactive,
  backgroundImage: `url(${Loader})`,
};
